import type { IApproval } from '@Models/JoinToAuction/approval.model';
import { useHttpService } from '@/composables/httpService';

export function fetchApproval(itemId: string): Promise<IApproval | unknown> {
  const { useGet } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/bidder/approval?itemId=${itemId}`;
  return useGet(url);
}

export function fetchApprovals(itemId: number): Promise<unknown> {
  const { useGet } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/join-auction-roles?itemId=${itemId}`;
  return useGet(url);
}

export function fetchPrincipalData(): Promise<unknown> {
  const { useGet } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.backOfficeServiceLK}/rest/user/data-to-join-auction`;
  return useGet(url);
}

export function joinToAuction(data: any): Promise<unknown> {
  const { usePostForFormData } = useHttpService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/join-auction`;
  return usePostForFormData(url, data);
}
